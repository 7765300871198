import { EventEmitter, Injectable } from '@angular/core';
import * as moment from 'moment';

import { USER_AUTHENTICATION_INFO, USER_DATE_SELECTED } from '../constants';
import { UserAuth } from '../model/class/userAuth';
import { IUnitDetails } from '../model/interfaces/IUnit';
import { IUserDetails } from '../model/interfaces/IUserDetails';

interface dateCache {
  expiration: string;
  date: string;
}
@Injectable({
  providedIn: 'root',
})
export class CacheService {
  protected rememberLogin = false;
  protected units: IUnitDetails[] = [];
  public userInfoChanged = new EventEmitter<UserAuth>();

  constructor() {}

  setUnits(units: IUnitDetails[]) {
    this.units = units;
  }

  getUnits() {
    return this.units;
  }

  getRememberLogin() {
    return this.rememberLogin;
  }

  /**
   * Método que retorna os dados do usuario salvos no cache
   */
  getLoggedUserInfo(): UserAuth {
    let result = JSON.parse(localStorage.getItem(USER_AUTHENTICATION_INFO) as string);
    if (result === null) {
      result = JSON.parse(sessionStorage.getItem(USER_AUTHENTICATION_INFO) as string);
    }
    return result;
  }

  /**
   * Método que insere os dados do usuario do cache
   */
  setLoggedUserInfo(userAuthInfo: UserAuth, remember: boolean): Promise<UserAuth> {
    this.removeUserAuth();
    userAuthInfo.remember = remember;
    if (remember) {
      localStorage.setItem(USER_AUTHENTICATION_INFO, JSON.stringify(userAuthInfo));
    } else {
      sessionStorage.setItem(USER_AUTHENTICATION_INFO, JSON.stringify(userAuthInfo));
    }
    this.userInfoChanged.emit(userAuthInfo);
    return Promise.resolve(userAuthInfo);
  }

  /**
   * Método que remove os dados do usuario do cache
   */
  removeUserAuth() {
    localStorage.removeItem(USER_AUTHENTICATION_INFO);
    sessionStorage.removeItem(USER_AUTHENTICATION_INFO);
  }

  /**
   * Método que verifica se o usuario está autenticado
   */
  checkUserAuth() {
    const userInfo = this.getLoggedUserInfo();
    if (userInfo === null) {
      return false;
    }

    const now = new Date();
    const expirationDate = new Date(userInfo.expiration);
    if (expirationDate.valueOf() - now.getTimezoneOffset() < now.valueOf()) {
      this.removeUserAuth();
      return false;
    }

    return true;
  }

  /**
   * Método que retorna se o usuario está autenticado
   */
  isUserAuthenticated(): boolean {
    this.checkUserAuth();
    const userInfo = this.getLoggedUserInfo();
    return userInfo != null;
  }

  /*
   * Método que grava o nome do usuario no cache
   */
  setUserDetails(userDetails: IUserDetails) {
    const user = this.getLoggedUserInfo();
    user.userDetails = userDetails;
    this.setLoggedUserInfo(user, user.remember);
  }

  /*
   * Método que retorna o nome do usuario, salvo no cache
   */
  getUserDetails() {
    return this.getLoggedUserInfo().userDetails;
  }

  /**
   * Método que retorna o ID da unidade, salvo no cache
   */
  getUnit(): string {
    const userInfo = this.getLoggedUserInfo();
    return userInfo !== null ? userInfo.arenaID : null;
  }

  /*
   * Método que grava o ID da unidade do usuario
   */
  setUnity(arenaID: string) {
    const user = this.getLoggedUserInfo();
    user.arenaID = arenaID;
    this.setLoggedUserInfo(user, user.remember);
  }

  /**
   * Método que retorna o ID do usuário, salvo no cache
   */
  getUserID() {
    const userInfo = this.getLoggedUserInfo();
    return userInfo !== null ? userInfo.userID : null;
  }

  getDateSelectedCalendar() {
    const dateCache = sessionStorage.getItem(USER_DATE_SELECTED);
    let dateCacheFormated: dateCache;
    if (dateCache) {
      dateCacheFormated = JSON.parse(dateCache) as dateCache;
      let expirationDate = moment(dateCacheFormated.expiration);
      let duration = moment.duration(expirationDate.diff(moment()));
      if (duration.days() < 0 || duration.hours() < 0) {
        return moment().format('YYYY-MM-DD');
      }
      return dateCacheFormated.date;
    }
    return moment().format('YYYY-MM-DD');
  }

  setDateSelectedCalendar(dateSelected: string) {
    const dateCache = {
      date: dateSelected,
      expiration: moment().add(1, 'days'),
    };
    sessionStorage.setItem(USER_DATE_SELECTED, JSON.stringify(dateCache));
  }

  // setUnitDetails(unitDetails: IUnit[]) {
  //   const user = this.getLoggedUserInfo();
  //   user.units = unitDetails;
  //   this.setLoggedUserInfo(user, user.remember);
  // }

  // getUnitDetails() {
  //   const userInfo = this.getLoggedUserInfo();
  //   return userInfo !== null ? userInfo.units : null;
  // }
}
