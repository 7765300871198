import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { CacheService } from 'src/app/shared/services/cache.service';
import { SecurityService } from 'src/app/shared/services/security.service';
import { environment } from 'src/environments/environment';

import { TOKEN_ANONYMOUS } from '../constants';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cacheService: CacheService, private securityService: SecurityService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('x-skip-autorizacao')) {
      return next.handle(
        req.clone({
          headers: req.headers.delete('x-skip-autorizacao'),
          setHeaders: {
            Authorization: `${TOKEN_ANONYMOUS}`,
          },
        })
      );
    } else {
      this.checkTokenExpiration();
    }

    const userInfo = this.cacheService.getLoggedUserInfo();

    const dupReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${userInfo !== null ? userInfo.accessToken : null}`),
    });
    return next.handle(dupReq);
  }

  errorHandler(error: any) {
    if (!environment.production) {
      console.error(error);
    }
  }

  checkTokenExpiration() {
    const userInfo = this.cacheService.getLoggedUserInfo();

    if (!userInfo || !userInfo.expiration) {
      // Se não houver informação do usuário ou data de expiração, redireciona para login
      this.redirectToLogin();
      return;
    }

    const currentDate = moment();
    const expirationDate = moment(userInfo.expiration);

    if (currentDate.isAfter(expirationDate)) {
      // Token expirado, redireciona para login ou renova o token
      this.redirectToLogin();
    }
  }

  redirectToLogin() {
    this.securityService.logout();
  }
}
