import { IUnitDetails } from '../interfaces/IUnit';
import { IUserDetails } from '../interfaces/IUserDetails';

export class UserAuth {
  accessToken: string;
  authenticated: boolean;
  created: string;
  expiration: string;
  message: string;
  permisions: string[];
  userID: string;
  userDetails: IUserDetails; //only front
  arenaID: string; // only front
  units: string[]; //only front
  remember: boolean; //only front
  dateSelected: string; //only front
}
