import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { SecurityService } from 'src/app/shared/services/security.service';

interface IMenu {
  icon: string;
  name: string;
  url: string;
  showMobile: boolean;
}

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent implements OnInit {
  @Input() showMenu = true;

  @ViewChild('moreOptionsPop', { static: false }) moreOptionsPop: PopoverDirective;

  showBadgeRegistration = false;

  menu: IMenu[] = [
    {
      icon: 'calendar_month',
      name: 'Reservas',
      url: '/reservas',
      showMobile: true,
    },
    {
      icon: 'support_agent',
      name: 'Vendas',
      url: '/vendas',
      showMobile: true,
    },
    {
      icon: 'domain',
      name: 'Empresa',
      url: '/b2b',
      showMobile: false,
    },
    // {
    //   icon: 'face',
    //   name: 'Appito Jr.',
    //   url: '/escolinha',
    //   showMobile: true,
    // },
    {
      icon: 'monetization_on',
      name: 'Financ.',
      url: '/financeiro',
      showMobile: false,
    },
    {
      icon: 'more_horiz',
      name: null,
      url: '/more',
      showMobile: true,
    },
  ];

  menuActive = false;

  constructor(private route: ActivatedRoute, private securityService: SecurityService) {}

  ngOnInit(): void {
    if (this.route.snapshot.routeConfig.path === 'unidades') {
      this.menuActive = true;
    }
  }

  logout() {
    this.securityService.logout();
  }
}
