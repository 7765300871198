<div class="header" *ngIf="title">
  <h1 class="typography title large">{{ title }}</h1>
</div>

<div class="under-construction">
  <div class="main">
    <img src="../../assets/images/icon-construction.svg" alt="" />
    <span class="typography title large"> Página em construção </span>
  </div>
</div>
