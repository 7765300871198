// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const API_URL = 'http://40.89.253.171/';
// const API_URL = 'http://20.236.195.182/';
const API_URL = 'https://manager-api-qa.appito.com/';

export const environment = {
  production: false,
  userAPI: `${API_URL}api/user`,
  unitAPI: `${API_URL}api/unit`,
  bookingAPI: `${API_URL}api/booking`,
  notificationAPI: `${API_URL}api/notification`,
  loginAPI: `${API_URL}api/login`,
  emailAPI: `${API_URL}api/email`,
  spaceAPI: `${API_URL}api/space`,
  matchAPI: `${API_URL}api/match`,
  commentsAPI: `${API_URL}api/comments`,
  financialAPI: `${API_URL}api/financial`,
  schoolAPI: `${API_URL}ms-footballschool/api/FootballSchool`,
  salesAPI: `${API_URL}api/event`,
  utilAPI: `${API_URL}api/utils/`,
  paymentsAPI: `${API_URL}api/payments`,
};

// export const environment = {
//   production: false,
//   userAPI: `${API_URL}ms-user/api/user`,
//   unitAPI: `${API_URL}ms-unit/api/unit`,
//   bookingAPI: `${API_URL}ms-booking/api/booking`,
//   notificationAPI: `${API_URL}ms-notification/api/notification`,
//   loginAPI: `${API_URL}ms-login/api/login`,
//   emailAPI: `${API_URL}ms-email/api/email`,
//   spaceAPI: `${API_URL}ms-space/api/space`,
//   matchAPI: `${API_URL}ms-match/api/match`,
//   commentsAPI: `${API_URL}ms-comments/api/comments`,
//   financialAPI: `${API_URL}ms-financial/api/financial`,
//   schoolAPI: `${API_URL}ms-footballschool/api/FootballSchool`,
//   salesAPI: `${API_URL}ms-appitoevents/api/Events`,
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import `zone.js/dist/zone-error`;  // Included with Angular CLI.
