import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreService } from './core/core.service';
import { AuthInterceptor } from './shared/services/authInterceptor.service';
import { LoaderService } from './shared/services/loader.service';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule, SwPush, SwUpdate } from '@angular/service-worker';
import { NgSelectConfig } from '@ng-select/ng-select';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

// Providers
registerLocaleData(ptBr);

moment.updateLocale('pt-br', {
  weekdays: 'Domingo_Segunda-feira_Terça-feira_Quarta-feira_Quinta-feira_Sexta-feira_Sábado'.split('_'),
  week: {
    dow: 0, //para semana começar no domingo
  },
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    LoaderService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private swPush: SwPush,
    private swUpdate: SwUpdate,
    private coreService: CoreService,
    private config: NgSelectConfig
  ) {
    this.swUpdate.available.subscribe({
      next: (event) => {
        console.log('New update available');
        this.swUpdate.activateUpdate().then(() => document.location.reload());
      },
    });

    if (this.swPush.isEnabled) {
      this.swPush.messages.subscribe({
        next: (event) => {
          this.coreService.sendNotification.emit(event);
        },
      });

      // this.swPush.notificationClicks.subscribe((event) => {});
    }

    this.config.notFoundText = '-';
  }
}
